// User Actions
export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION';
export const LOGOUT = 'LOGOUT';
export const SET_USER_LIST = 'SET_USER_LIST';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_TO_USER_ARRAY = 'ADD_TO_USER_ARRAY';
export const SET_EXPANDED_USER_ID = 'SET_EXPANDED_USER_ID';

// Registration Actions
export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const DELETE_REGISTRATION = 'DELETE_REGISTRATION';
export const PATCH_REGISTRATION = 'PATCH_REGISTRATION';

// Tournament Actions
export const GET_TOURNAMENT = 'GET_TOURNAMENT';
export const SET_TOURNAMENT_LIST = 'SET_TOURNAMENT_LIST';
export const ADD_TOURNAMENT = 'ADD_TOURNAMENT';
export const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT';
export const DELETE_TOURNAMENT = 'DELETE_TOURNAMENT';
export const TOGGLE_SHOW_PREV_TOURNAMENTS = 'TOGGLE_SHOW_PREV_TOURNAMENTS';
export const SET_EXPANDED_TOURNAMENT_ID = 'SET_EXPANDED_TOURNAMENT_ID';

// Bug Actions
export const GET_ALL_BUGS = 'GET_ALL_BUGS';
export const SET_BUG_STATUS = 'SET_BUG_STATUS';
export const ADD_BUG = 'ADD_BUG';
export const REMOVE_BUG = 'REMOVE_BUG';
